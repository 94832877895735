// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

global.toastr = require("toastr")
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import { Application } from '@hotwired/stimulus'
Rails.start()
Turbolinks.start()
ActiveStorage.start()

const application = Application.start()

const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')
require('bootstrap-icons/font/bootstrap-icons.css')
import "./style.scss"

document.addEventListener("turbolinks:load", function () {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
  var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    return new bootstrap.Dropdown(dropdownToggleEl)
  })
});
