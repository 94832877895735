import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["enteries", "pagination", "loader"]

  connect() {
    this.container.addEventListener('scroll', () => {
      this.scroll()
    })
    let prevPage = this.paginationTarget.querySelector('li.page-item.active > a')
    if (prevPage != null) {
      this.prevURL = prevPage.href
    }
  }

  scroll() {
    let nextPage = this.paginationTarget.querySelector("a[rel='next']")
    if (nextPage == null) { return }
    let url = nextPage.href

    if (this.container.scrollHeight - this.container.scrollTop <= this.container.clientHeight && url != this.prevURL) {
      this.prevURL = url
      this.loadMore(url)
    }
  }

  loadMore(url) {
    this.loaderTarget.classList.remove('d-none')
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.lastPage = data.last_page
        this.enteriesTarget.insertAdjacentHTML('beforeend', data.enteries)
        this.paginationTarget.innerHTML = data.pagination
        this.loaderTarget.classList.add('d-none')
      }
    })
  }

  get container() {
    return document.querySelector('#scrollable-container');
  }
}